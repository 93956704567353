import {Children, cloneElement, useState, useEffect, useRef} from 'react'
import css from 'styled-jsx/css'
import {classnames} from '@@client/lib/classes'
import {getStampMs} from '@@client/lib/dates'
import {debounce} from '@@client/lib/util'
import {useInterval} from '@@client/lib/hooks'
import Draggable from './Draggable'

const controlStyle = css.resolve`
    button {
        user-select: none;
        cursor: pointer;
        border: 0;
    }
`

const controlArrowStyle = css.resolve`
    @import '@css/variables.css';

    button {
        background: transparent;
        padding: 5px;

        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-top: 2px solid $c--blue--darker;
            border-right: 2px solid $c--blue--darker;
        }
    }
`

export const PrevControl = ({onClick, controls}) => {
    const classNames = classnames([
        'control',
        'control-prev',
        controlStyle.className,
        controlArrowStyle.className,
    ])

    return (
        <>
            <button aria-label='Show previous slide' aria-controls={controls} className={classNames} onClick={onClick} />
            {controlStyle.styles}
            {controlArrowStyle.styles}
            <style jsx>
                {`
                    button:after {
                        transform: rotate(-135deg);
                    }
                `}
            </style>
        </>
    )
}

export const NextControl = ({onClick, controls}) => {
    const classNames = classnames([
        'control',
        'control-next',
        controlStyle.className,
        controlArrowStyle.className,
    ])

    return (
        <>
            <button aria-label='Show next slide' aria-controls={controls} className={classNames} onClick={onClick} />
            {controlStyle.styles}
            {controlArrowStyle.styles}
            <style jsx>
                {`
                    button:after {
                        transform: rotate(45deg);
                    }
                `}
            </style>
        </>
    )
}

const Indicator = ({controls, isCurrent, onClick, numSlides, slideNumber}) => {
    const classNames = classnames([
        'indicator',
        controlStyle.className,
        isCurrent ? 'is-current' : null,
    ])

    const label = `Show slide ${slideNumber + 1} of ${numSlides}`

    return (
        <>
            <button aria-label={label} aria-controls={controls} className={classNames} onClick={onClick} />
            {controlStyle.styles}
            <style jsx>
                {`
                    @import '@css/variables.css';

                    .indicator {
                        background-color: transparent;
                        padding: 10px;
                        outline: 0;
                        border: 0;

                        /* put the icon in after so we can pad the parent (make click area bigger) and keep the cicle smaller. */
                        &:after {
                            content: '';
                            display: block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            transition: background-color 200ms ease-out;
                            background: transparent;
                            border: 1px solid $c--blue--darker;
                        }

                        &.is-current:after,
                        &:hover:after {
                            background-color: $c--blue--darker;
                        }
                    }

                    .is-current:after {
                        background-color: red;
                    }
                `}
            </style>
        </>
    )
}

export const Indicators = ({controls, num, currentSlide, onClick}) => {
    if(!num) return null

    return (
        <>
            <div className='indicators'>
                {Array.from(Array(num)).map((_, i) => (<Indicator key={i} controls={controls} numSlides={num} slideNumber={i} isCurrent={i === currentSlide} onClick={() => onClick(i)} />))}
            </div>
        </>
    )
}

export default function Carousel({
    children,
    label = 'Carousel',
    trackId = '',
    resizeOnChange = false, // should resize to the height of the current slide
    renderNextPrevControls = true,
    renderIndicators = true,
    hideControlsWhenOnePage = true,
    autorotate = false, // should be an integer (number of milliseconds) or false
    slideContainerRef = null, // if you want to provide a custom slide container. this is only used to set the `left` style, in order to slide left/right.
    numSlides = null, // if null, will be set to the number of children
    onSlideChange = () => {},
    onNumSlidesChange = () => {},
    triggerSlideNext = () => {}, // if provided, takes a callback function as a parameter. when a slide change is triggered from outside, will call the callback function
    triggerSlidePrev = () => {}, // if provided, takes a callback function as a parameter. when a slide change is triggered from outside, will call the callback function
    triggerSlideTo = () => {}, // if provided, takes a callback function as a parameter. when a slide change is triggered from outside, will call the callback function
    triggerResize = () => {}, // if provided, takes a callback function as a parameter, when the carousel needs to resize, will call the callback function
}) {
    const slideContainerRefInternal = useRef(null)
    const [lastActionStamp, setLastActionStamp] = useState(getStampMs())
    const [currentSlide, _setCurrentSlide] = useState(0)
    const [isDragging, setIsDragging] = useState(false)
    const [dragOffset, setDragOffset] = useState(0)

    numSlides = numSlides === null
        ? Children.count(children)
        : numSlides

    // make sure currentSlide is still within acceptable range (the number of slides might change with screen resize and currentSlide could be out of range)
    useEffect(() => {
        if(currentSlide >= numSlides) _setCurrentSlide(numSlides - 1)
    }, [numSlides])

    useEffect(() => {
        onNumSlidesChange(numSlides)
    }, [numSlides])

    useEffect(() => {
        updateContainerHeight()
    }, [currentSlide, resizeOnChange])

    // NOTE: We want to reset the timer when the user manually navigates. We could keep track of when
    // that last happened and just not rotate if it happened recently enough. But the timer is reset
    // whenever any of the listed dependecies changes (including currentSlide and lastActionStamp), so
    // we essentially get the reset for free. Also, the other reason we need to include these dependecies
    // is if we don't, their values will be saved in the callback's context and they'll essentially never
    // change from the callback's perspective (so we'll get one autorotate and then no more because it
    // will keep trying to autorate to the same page over and over).
    useInterval(() => goNext(), autorotate, {watchForChanges: [autorotate, currentSlide, numSlides, lastActionStamp]})

    useEffect(() => {
        if(!resizeOnChange) return

        const handleResize = debounce(() => {
            updateContainerHeight()
        }, 500)

        window.addEventListener('resize', handleResize)

        const off = () => window.removeEventListener('resize', handleResize)

        return off
    }, [currentSlide, resizeOnChange]) // need to add currentSlide so the original value isn't saved in context every time this is called

    const updateContainerHeight = () => {
        if(!resizeOnChange) return

        const containerRef = slideContainerRef || slideContainerRefInternal
        if(!containerRef?.current) return

        const currentSlideEl = containerRef.current.childNodes?.[currentSlide]
        if(!currentSlideEl) return

        // no height
        if(!currentSlideEl.scrollHeight) return

        // make height equal to current slide
        containerRef.current.style.height = currentSlideEl.scrollHeight + 'px'
    }

    const setCurrentSlide = x => {
        setLastActionStamp(getStampMs())

        if(x < 0) _setCurrentSlide(numSlides - 1) // loop to last slide
        else if (x >= numSlides) _setCurrentSlide(0) // loop to first slide
        else _setCurrentSlide(x)
    }

    const handleIndicatorClick = setCurrentSlide

    const goNext = () => setCurrentSlide(currentSlide + 1)
    const goPrev = () => setCurrentSlide(currentSlide - 1)

    triggerSlideNext(goNext)
    triggerSlidePrev(goPrev)
    triggerSlideTo(setCurrentSlide)
    triggerResize(updateContainerHeight)

    const handleStart = e => {
        if(numSlides <= 1) {
            setIsDragging(false)
            setDragOffset(0)
            return
        }

        setLastActionStamp(getStampMs())
        setIsDragging(true)
    }

    const handleMove = (e, {x, offsetX}) => {
        if(numSlides <= 1) {
            setIsDragging(false)
            setDragOffset(0)
            return
        }

        setLastActionStamp(getStampMs())
        setDragOffset(offsetX)
    }

    const handleEnd = (e, {x, offsetX}) => {
        if(numSlides <= 1) {
            setIsDragging(false)
            setDragOffset(0)
            return
        }

        setLastActionStamp(getStampMs())

        if(Math.abs(offsetX) > 50) {
            if(offsetX > 0) goPrev()
            else goNext()
        }

        setIsDragging(false)
        setDragOffset(0)
    }

    const renderSlides = children => {
        // need to deal with the case where a child could be null
        return Children.map(children, (child, i, x) => !child ? child : cloneElement(child, {
            'aria-roledescription': 'slide',
            'aria-label': `Slide ${i + 1} of ${numSlides}`,
            'role': 'group',
        }))
    }

    const slideOffset = -1 * currentSlide * 100

    const trackClassNames = classnames([
        'track',
        isDragging ? 'is-dragging' : null,
    ])

    const buildLeftOffsetStyleString = () => `calc(${slideOffset}% + ${dragOffset}px)`

    const slidesClassNames = classnames([
        'slides',
        resizeOnChange ? 'resize-on-change' : null,
    ])

    useEffect(() => {
        onSlideChange({currentSlide, numSlides, controls: trackId})
    }, [currentSlide, numSlides])

    // if slide container ref is provided, set its offset
    useEffect(() => {
        if(slideContainerRef?.current) {
            slideContainerRef.current.style.left = buildLeftOffsetStyleString()
        }
    }, [dragOffset, slideOffset])

    return (
        <>
            <div className='carousel' role='region' aria-roledescription='carousel' aria-label={label}>
                {renderNextPrevControls && (!hideControlsWhenOnePage || numSlides > 1) ? <PrevControl controls={trackId} onClick={goPrev} /> : null}
                <Draggable onStart={handleStart} onMove={handleMove} onEnd={handleEnd}>
                    <div className={trackClassNames} id={trackId}>
                        <div className={slidesClassNames} ref={slideContainerRefInternal} style={slideContainerRef ? {} : {left: buildLeftOffsetStyleString()}}>
                            {renderSlides(children)}
                        </div>
                    </div>
                </Draggable>
                {renderNextPrevControls && (!hideControlsWhenOnePage || numSlides > 1) ? <NextControl controls={trackId} onClick={goNext} /> : null}
                {renderIndicators && (!hideControlsWhenOnePage || numSlides > 1) ? <Indicators controls={trackId} num={numSlides} currentSlide={currentSlide} onClick={handleIndicatorClick} /> : null}
            </div>
            <style jsx>
                {`
                    .track {
                        width: 100%;
                        overflow: hidden;

                        &.is-dragging {
                            user-select: none;
                        }

                        .slides {
                            position: relative;
                            display: flex;
                            transition: left 500ms ease, height 500ms ease;

                            &.resize-on-change {
                                align-items: flex-start; /* this prevents items from being the same height, so we can resize */
                            }

                            & > :global(*) {
                                flex: 0 0 100%;
                            }
                        }

                        &.is-dragging .slides {
                            transition: none;
                        }
                    }
                `}
            </style>
        </>
    )
}
